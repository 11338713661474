<template>
  <div class="py-4">
    <p class="text-body-1 blue100--text font-weight-bold mb-1">
      Not required but highly recommended
    </p>
    <p class="text-body-2 blue300--text">
      You need to have your email verified in settings for this to work.
    </p>

    <div class="text-right">
      <v-btn
        color="grey300 grey100--text" tile
        depressed :ripple="false"
        class="mr-3"
        @click="$emit('update', false)"
      >
        Skip
      </v-btn>

      <v-btn
        color="blue700 blue200--text" tile
        depressed :ripple="false"
        large
        :disabled="!valid"
        :loading="loading"
        @click="$emit('update', true)"
      >
        Send test email
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    valid: Boolean,
  },
}
</script>
