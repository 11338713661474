<template>
  <div class="py-4">
    <v-row>
      <v-col cols="12" md="6">
        <h4 class="text-uppercase text-caption mb-1">
          Instant unsubscribe group
        </h4>
        <v-autocomplete
          v-model="form.group"
          :items="fieldTypes"
          solo background-color="grey600"
          hide-details
          class="rounded-0"
          label="Instant unsubscribe group"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h4 class="text-uppercase text-caption mb-1">
          Unsubscribe preferences groups
        </h4>
        <v-autocomplete
          v-model="form.groups"
          :items="fieldTypes"
          multiple
          solo background-color="grey600"
          hide-details
          class="rounded-0"
          label="Unsubscribe preferences groups"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        group: 17119,
        groups: [17119],
      },
    }
  },
  computed: {
    fieldTypes() {
      return [
        { text: 'Promotions', value: 17119 },
        { text: 'Offerwall lock/unlock', value: 17175 },
        { text: 'Self-locks', value: 17176 },
        { text: 'News & updates', value: 17187 },
      ]
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', {
          ...val,
          name: this.fieldTypes.find(i => i.value === val.group)?.text,
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.templates-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 16px;
}
</style>
