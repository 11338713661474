<template>
  <v-row>
    <v-col cols="12" md="6">
      <h4 class="text-uppercase text-caption mb-1">
        Reward type
      </h4>
      <v-autocomplete
        v-model="form.type"
        :items="rewardTypes"
        solo background-color="grey600"
        hide-details
        class="rounded-0"
        label="Reward type"
      />
    </v-col>
    <v-col cols="12" md="6">
      <h4 class="text-uppercase text-caption mb-1">
        Reward expiration
      </h4>
      <v-text-field
        v-model="form.expiration"
        type="text"
        solo background-color="grey600"
        persistent-hint
        class="rounded-0 mb-n4"
        :rules="durationRules"
        hint="5mins, 2.5 hrs, hours, days, weeks, etc.."
        label="Expiration"
      />
    </v-col>
    <v-col cols="12">
      <h4 class="text-uppercase text-caption mb-1">
        Reward data
      </h4>

      <div ref="editor" class="editor grey100" />

      <v-alert
        dense tile
        class="text-body-2 white--text"
        :class="editorError ? 'primary700' : 'green800'"
      >
        <div class="d-flex align-center">
          <fai :icon="['fad', editorError ? 'angry' : 'robot']" class="text-h6 mr-3" fixed-width />
          <span class="mt-1">{{ editorError ? editorError : 'Looks good so far' }}</span>
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import ms from 'ms'
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.min.css'

const REWARD_DATA = {
  scrap: {
    amount: 100,
    reason: 'promo name here',
  },
  items: {

    items: [
      {
        name: 'Blue Cap', canBet: true, canWithdraw: false, canSell: true,
      },
      {
        name: 'Black Beenie Hat', canBet: true, canWithdraw: true, canSell: true,
      },
    ],
    reason: 'promo name here',
  },
  level_case: {
    tier: 1,
    level: 12,
    reason: 'promo name here',
  },
}

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
      editorError: null,
      form: { ...this.value },
      expiration: null,
    }
  },
  computed: {
    rewardTypes() {
      return [
        'level_case',
        'scrap',
        'items',
      ]
    },
    durationRules() {
      const convert = v => (v ? ms(v) : undefined)

      return [
        v => convert(v) !== undefined || 'Invalid value',
        v => (typeof convert(v) === 'number') || 'Cannot be just a number',
        v => convert(v) >= 0 || 'Must be positive',
      ]
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('input', {
          ...val,
          expiration: val.expiration && ms(val.expiration),
        })
      },
      deep: true,
      immediate: true,
    },
    'form.type': function (val) {
      this.form.data = REWARD_DATA[val] ?? {}
      this.editor.set(this.form.data)
    },
  },
  beforeDestroy() {
    this.editor?.destroy()
  },
  mounted() {
    this.editor = new JSONEditor(this.$refs.editor, {
      mode: 'code',
      enableTransform: false,
      enableSort: false,
      onChange: this.onEditorInput,
    })

    this.editor.set(this.form.data)
  },
  methods: {
    onEditorInput() {
      try {
        const validated = this.editor.get()
        this.editorError = null
        this.form.data = validated
      } catch (e) {
        this.editorError = e.message
      }
    },
  },
}
</script>
