<template>
  <div>
    <h3 class="text-h6 mb-4 font-weight-bold">
      Set up a new email campaign
    </h3>

    <!-- create bot -->
    <section class="mb-6">
      <v-expansion-panels multiple tile>
        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.audience" color="grey600">
            1. Set up a target audience
            <template v-if="finishedSteps.audience" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">{{ audience.total }} users</span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsAudience @update="onAudienceUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.template" color="grey600">
            2. Pick a design template
            <template v-if="finishedSteps.template" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">{{ template.name }}</span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsTemplate @update="onTemplateUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.mapping" color="grey600">
            3. Map template variables
            <span v-if="mapping.templateVariables.length" class="ml-1 text--disabled">
              ({{ mapping.mapped.length }}/{{ mapping.templateVariables.length }} template vars)
            </span>
            <template v-if="finishedSteps.mapping" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">
                  {{ mapping.mapped.length }} variables mapped
                </span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsMapping :template="template" @update="onMappingUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.unsub" color="grey600">
            4. Set an unsubscribe group
            <template v-if="finishedSteps.unsub" #actions>
              <div class="d-flex align-center">
                <span class="mr-3 text-body-2 font-weight-semibold">
                  {{ unsub.name }}
                </span>
                <v-icon color="teal">
                  mdi-check
                </v-icon>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsUnsub @update="onUnsubUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.misc" color="grey600">
            5. Miscellaneous settings
            <template v-if="finishedSteps.misc" #actions>
              <v-icon color="teal">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsMisc @update="onMiscUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header :disable-icon-rotate="finishedSteps.test" color="grey600">
            6. Send yourself a test email
            <template v-if="finishedSteps.test" #actions>
              <v-icon color="teal">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminEmailsTest :loading="test.loading" :valid="canTest" @update="onTestUpdate" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>

    <section>
      <div class="text-right">
        <v-btn
          color="green700 green200--text" tile
          depressed :ripple="false"
          large
          :disabled="!valid"
          :loading="loading"
          @click="onSendEmails"
        >
          Send {{ audience.total }} emails
        </v-btn>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminEmailsAudience from './components/emails/AdminEmailsAudience'
import AdminEmailsTemplate from './components/emails/AdminEmailsTemplate'
import AdminEmailsMapping from './components/emails/AdminEmailsMapping'
import AdminEmailsUnsub from './components/emails/AdminEmailsUnsub'
import AdminEmailsMisc from './components/emails/AdminEmailsMisc'
import AdminEmailsTest from './components/emails/AdminEmailsTest'

export default {
  components: {
    AdminEmailsAudience,
    AdminEmailsTemplate,
    AdminEmailsMapping,
    AdminEmailsUnsub,
    AdminEmailsMisc,
    AdminEmailsTest,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      audience: {
        query: null,
        total: null,
      },
      template: {
        id: null,
        name: null,
      },
      mapping: {
        dirty: false,
        valid: false,
        variables: [],
        templateVariables: [],
        mapped: [],
      },
      unsub: {
        name: null,
        group: null,
        groups: null,
      },
      misc: {
        dirty: false,
      },
      test: {
        loading: false,
        dirty: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    finishedSteps() {
      return {
        audience: !!this.audience.total,
        template: !!this.template.id,
        mapping: this.mapping.dirty && this.mapping.valid,
        unsub: !!this.unsub.group,
        misc: this.misc.dirty,
        test: this.test.dirty,
      }
    },
    valid() {
      return Object.values(this.finishedSteps).every(Boolean)
    },
    canTest() {
      const obj = { ...this.finishedSteps }
      delete obj.test

      return Object.values(obj).every(Boolean)
    },
  },
  methods: {
    onAudienceUpdate(data) {
      this.audience.query = data.query
      this.audience.total = data.total
    },
    onTemplateUpdate(data) {
      this.template.id = data.id
      this.template.name = data.name
      this.template.versionId = data.versionId
    },
    onMappingUpdate(data) {
      this.mapping.dirty = true
      this.mapping.templateVariables = data.templateVariables

      this.mapping.variables = data.variables
      this.mapping.mapped = data.variables.filter(i => Object.values(i).every(Boolean))

      this.mapping.valid = this.mapping.mapped.length === data.variables.length
    },
    onUnsubUpdate(data) {
      this.unsub.name = data.name
      this.unsub.group = data.group
      this.unsub.groups = data.groups
    },
    onMiscUpdate(data) {
      this.misc = {
        ...data,
        dirty: true,
      }
    },
    onTestUpdate(send) {
      if (send) {
        this.onTestEmail()
      }

      this.test.dirty = true
    },
    //
    async onTestEmail() {
      this.test.loading = true

      try {
        const payload = await this.$socket.request('admin.emails.send', {
          query: { steamid: this.user.steamid },
          templateId: this.template.id,
          variables: this.mapping.variables,
          sender: this.misc.sender,
          asm: this.unsub,
          userRewards: this.misc.userRewards,
        })

        console.log(payload)

        this.$toast.info('Test email sent')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.test.loading = false
      }
    },
    async onSendEmails() {
      this.loading = true

      try {
        const payload = await this.$socket.request('admin.emails.send', {
          query: this.audience.query,
          templateId: this.template.id,
          variables: this.mapping.variables,
          sender: this.misc.sender,
          asm: this.unsub,
          userRewards: this.misc.userRewards,
        })

        console.log(payload)

        this.$toast.success('Emails sent')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
