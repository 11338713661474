<template>
  <div class="py-4">
    <v-row class="mb-4">
      <v-col cols="12" md="6">
        <h4 class="text-uppercase text-caption mb-1">
          Sender name
        </h4>
        <v-text-field
          v-model="form.sender.name"
          solo background-color="grey600"
          hide-details
          class="rounded-0"
          label="Sender name"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h4 class="text-uppercase text-caption mb-1">
          Sender email
        </h4>
        <v-autocomplete
          v-model="form.sender.email"
          :items="senderEmails"
          solo background-color="grey600"
          hide-details
          class="rounded-0"
          label="Sender email"
        />
      </v-col>
    </v-row>

    <v-card color="grey700" class="pa-4" tile>
      <h4 class="text-uppercase primary500--text text-body-2 mb-2">
        User rewards
      </h4>
      <p class="text-body-2 mb-6">
        This will generate a claimable user reward for every target audience member.
      </p>

      <AdminUserRewardsForm v-model="form.userRewards" />
    </v-card>
  </div>
</template>

<script>
import AdminUserRewardsForm from '../AdminUserRewardForm.vue'

export default {
  components: {
    AdminUserRewardsForm,
  },
  data() {
    return {
      editor: null,
      editorError: null,
      form: {
        sender: {
          name: 'bandit.camp system',
          email: 'system@banditcamp.com',
        },
        userRewards: {
          type: null,
          expiration: null,
          data: null,
        },
      },
    }
  },
  computed: {
    senderEmails() {
      return [
        'system@banditcamp.com',
        'noreply@banditcamp.com',
      ]
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.templates-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 16px;
}
</style>
