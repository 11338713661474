<template>
  <div class="py-4">
    <v-alert
      v-if="!template.id" type="error" dense tile
      color="primary700"
    >
      No template selected yet, so we cannot fetch the variables map.
    </v-alert>

    <div v-else class="text-right mb-3">
      <v-btn
        color="grey300"
        tile small
        :ripple="false" :loading="loading"
        :disabled="!template.id"
        @click="fetchData"
      >
        <fai :icon="['fas','sync']" class="mr-1" />
        Refresh variable map
      </v-btn>
    </div>

    <v-alert
      v-if="templateVariables.length" tile
      color="grey500"
    >
      <p class="text-body-2 mb-2">
        Following variables were found inside <code>test_data</code>, so you should probably fill them.
      </p>
      <code
        v-for="key in templateVariables" :key="key"
        class="mr-2"
        :class="isVariableFilled(key) && 'green600'"
      >{{ key }}</code>
    </v-alert>

    <!-- vars -->
    <div
      v-for="(variable, index) in variables" :key="index"
      class="mb-3 d-flex"
    >
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="variable.key"
            solo background-color="grey600"
            dense hide-details
            class="rounded-0"
            label="Key"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="variable.type"
            :items="fieldTypes"
            solo background-color="grey600"
            dense hide-details
            class="rounded-0"
            label="Type"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="variable.value"
            solo background-color="grey600"
            dense hide-details
            class="rounded-0"
            label="Value"
          />
        </v-col>
      </v-row>

      <v-btn
        color="primary600 primary100--text" tile class="unwidth unheight px-2 ml-2"
        @click="onRemove(index)"
      >
        <fai :icon="['fas', 'times']" />
      </v-btn>
    </div>

    <div class="text-right">
      <v-btn
        color="blue600 blue100--text" small tile class="text-none"
        @click="onAddNew"
      >
        <fai :icon="['fas', 'plus']" class="mr-2" />
        Add new variable
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      templateVariables: [],
      variables: [],
    }
  },
  computed: {
    fieldTypes() {
      return [
        { text: 'Text', value: 'text' },
        { text: 'User property (shallow)', value: 'user' },
      ]
    },
  },
  watch: {
    'template.id': {
      handler(id) {
        if (id) {
          this.fetchData()
        }
      },
      immediate: true,
    },
    variables: {
      handler(val) {
        this.$emit('update', {
          variables: val,
          templateVariables: this.templateVariables,
        })
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      if (!this.template?.id) return
      this.loading = true

      try {
        const templateVariables = await this.$socket.request('admin.emails.getTemplateVersionVariables', {
          templateId: this.template.id,
          versionId: this.template.versionId,
        })

        this.templateVariables = templateVariables
        const { variables } = this

        for (const variable of templateVariables) {
          // sue me
          if (!variables.find(i => i.key === variable)) {
            variables.push({ key: variable, type: null, value: null })
          }
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    isVariableFilled(key) {
      const variable = this.variables.find(i => i.key === key)
      if (!variable) return false

      return Object.values(variable).every(Boolean)
    },
    onRemove(index) {
      this.variables.splice(index, 1)
    },
    onAddNew() {
      this.variables.push({ key: null, type: null, value: null })
    },
  },
}
</script>

<style lang="scss" scoped>
.templates-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 16px;
}
</style>
