var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4"},[_c('div',{staticClass:"d-flex flex-wrap mb-3"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"grey300","tile":"","ripple":false,"loading":_vm.loading},on:{"click":_vm.fetchUsers}},[_c('fai',{staticClass:"grey100--text mr-1",attrs:{"icon":['fas','sync']}}),_vm._v(" Refresh ")],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"mr-2",staticStyle:{"max-width":"50px"},attrs:{"dense":"","hide-details":"","solo":"","label":"Lvl"},model:{value:(_vm.helpers.level),callback:function ($$v) {_vm.$set(_vm.helpers, "level", _vm._n($$v))},expression:"helpers.level"}}),_c('div',{staticClass:"font-weight-semibold"},[_vm._v(" = "+_vm._s(_vm.getXp(_vm.helpers.level))+" xp ")])],1)],1),_c('div',{ref:"editor",staticClass:"editor grey100"}),_c('v-alert',{staticClass:"text-body-2 white--text mb-6",class:_vm.editorError ? 'primary700' : 'green800',attrs:{"dense":"","tile":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('fai',{staticClass:"text-h6 mr-3",attrs:{"icon":['fad', _vm.editorError ? 'angry' : 'robot'],"fixed-width":""}}),_c('span',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.editorError ? _vm.editorError : 'Looks good so far'))])],1)]),_c('h5',{staticClass:"text-uppercase text-caption mb-2"},[_vm._v(" "+_vm._s(_vm.total)+" Matching users with verified emails ")]),_c('v-data-table',{staticClass:"rounded-0 data-table",attrs:{"headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [3, 5, 10, 25] },"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.xp",fn:function(ref){
var item = ref.item;
return [_vm._v(" Lvl. "+_vm._s(item.level)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('UserAvatar',{staticClass:"mr-2",attrs:{"user":item,"size":"24"}}),_c('Username',{attrs:{"no-flairs":"","user":item},scopedSlots:_vm._u([(item.nameOverride)?{key:"suffix",fn:function(){return [_c('span',{staticClass:"text--secondary text-caption ml-1"},[_vm._v("("+_vm._s(item.nameOverride)+")")])]},proxy:true}:null],null,true)})],1)]}},{key:"item.balance",fn:function(ref){
var value = ref.value;
return [_c('scrap',{attrs:{"amount":value}})]}},{key:"item.invTotal",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("toScrap")(value || 0)))])]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(value,'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(value,'YYYY-MM-DD HH:mm:ss')))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }