<template>
  <div class="py-4">
    <div class="d-flex flex-wrap mb-3">
      <v-btn
        color="grey300" class="mr-2"
        tile
        :ripple="false" :loading="loading"
        @click="fetchTemplates"
      >
        <fai :icon="['fas','sync']" class="grey100--text mr-1" />
        Refresh
      </v-btn>
    </div>

    <div class="templates-container">
      <v-card
        v-for="template in templates" :key="template.id"
        color="grey600" class="pos-relative d-flex flex-column"
      >
        <v-hover>
          <template #default="{hover}">
            <div class="pos-relative">
              <v-img :src="template.thumbnailUrl" contain aspect-ratio="1" />
              <v-overlay
                v-if="hover"
                absolute
                class="cursor-pointer"
                color="#036358"
                @click.native="onEnlarge(template.thumbnailUrl)"
              >
                <v-icon>mdi-magnify-plus-outline</v-icon>
              </v-overlay>
            </div>
          </template>
        </v-hover>
        <v-card-text class="flex-grow-1">
          <h4 class="text-body-2 font-weight-semibold blue300--text lh-1-2">
            {{ template.name }}
            <span class="font-weight-regular blue100--text">
              ({{ template.versionName }})
            </span>
          </h4>
        </v-card-text>
        <v-card-actions dense class="justify-end">
          <v-btn
            :color="selected === template.id ? 'green500' : ''"
            text small
            @click="selected = template.id"
          >
            {{ selected === template.id ? 'Selected' : 'Select' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      templates: [],
      selected: null,
    }
  },
  watch: {
    selected(id) {
      const template = this.templates.find(i => i.id === id)
      this.$emit('update', template)
    },
  },
  mounted() {
    this.fetchTemplates()
  },
  methods: {
    async fetchTemplates() {
      this.loading = true

      try {
        this.templates = await this.$socket.request('admin.emails.getTemplates')
        this.selected = null
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onEnlarge(url) {
      console.log(url)
      try {
        const win = window.open(url, '_blank')
        win.focus()
      } catch (error) {
        throw new Error('Your browser blocked the popup window')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.templates-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 16px;
}
</style>
