<template>
  <div class="py-4">
    <div class="d-flex flex-wrap mb-3">
      <v-btn
        color="grey300" class="mr-2"
        tile
        :ripple="false" :loading="loading"
        @click="fetchUsers"
      >
        <fai :icon="['fas','sync']" class="grey100--text mr-1" />
        Refresh
      </v-btn>

      <div class="d-flex align-center">
        <v-text-field
          v-model.number="helpers.level" dense hide-details solo
          label="Lvl" class="mr-2" style="max-width: 50px;"
        />
        <div class="font-weight-semibold">
          = {{ getXp(helpers.level) }} xp
        </div>
      </div>
    </div>

    <div ref="editor" class="editor grey100" />

    <v-alert
      dense tile
      class="text-body-2 white--text mb-6"
      :class="editorError ? 'primary700' : 'green800'"
    >
      <div class="d-flex align-center">
        <fai :icon="['fad', editorError ? 'angry' : 'robot']" class="text-h6 mr-3" fixed-width />
        <span class="mt-1">{{ editorError ? editorError : 'Looks good so far' }}</span>
      </div>
    </v-alert>

    <h5 class="text-uppercase text-caption mb-2">
      {{ total }} Matching users with verified emails
    </h5>

    <v-data-table
      class="rounded-0 data-table"
      :headers="headers"
      :items="entries"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [3, 5, 10, 25] }"
      :server-items-length="total"
      :loading="loading"
    >
      <template #item.xp="{ item }">
        Lvl. {{ item.level }}
      </template>
      <template #item.name="{ item }">
        <div class="d-flex align-center">
          <UserAvatar :user="item" size="24" class="mr-2" />
          <Username no-flairs :user="item">
            <template v-if="item.nameOverride" #suffix>
              <span class="text--secondary text-caption ml-1">({{ item.nameOverride }})</span>
            </template>
          </Username>
        </div>
      </template>
      <template #item.balance="{ value }">
        <scrap :amount="value" />
      </template>
      <template #item.invTotal="{ value }">
        <span>${{ value || 0 | toScrap }}</span>
      </template>
      <template #item.createdAt="{ value }">
        <v-tooltip top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ value | relativeTime('twitter') }}</span>
          </template>
          <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.min.css'

import { debounce } from '@/utils'
import { LEVELS } from '@/utils/constants'

const DEFAULT_QUERY = {
  xp: { $gte: 100e2 },
  lastLogin: { $lte: new Date('2021-06-01').toISOString() },
}

export default {
  data() {
    return {
      loading: false,
      editor: null,
      editorError: null,
      query: DEFAULT_QUERY,
      total: 0,
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 3,
        mustSort: true,
      },
      headers: [
        { text: 'User', value: 'name', align: 'start' },
        { text: 'Balance', value: 'balance' },
        { text: 'Inv value', value: 'invTotal' },
        {
          text: 'Lvl', value: 'xp', align: 'start', width: 80,
        },
        { text: 'Member since', value: 'createdAt', align: 'end' },
      ],
      entries: [],
      helpers: {
        level: null,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchUsers()
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.editor?.destroy()
  },
  mounted() {
    this.editor = new JSONEditor(this.$refs.editor, {
      mode: 'code',
      enableTransform: false,
      enableSort: false,
      onChange: this.onInput,
    })

    this.editor.set(DEFAULT_QUERY)
  },
  methods: {
    fetchUsers: debounce(async function fetchUsers() {
      if (this.editorError) return
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.emails.getUsers', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          query: this.query,
        })

        this.entries = entries.map(i => ({
          ...i,
          createdAt: new Date(i.createdAt).getTime(),
          level: this.getLevel(i.xp),
        }))

        this.total = total
        this.$emit('update', { total, query: this.query })
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }, 500),
    onInput() {
      try {
        const validated = this.editor.get()
        this.editorError = null
        this.query = validated
        this.fetchUsers()
      } catch (e) {
        this.editorError = e.message
      }
    },
    getLevel(xp) {
      const nextLevel = LEVELS.findIndex(lvl => lvl > xp ?? 0)

      // we return nextLevel because index starts at 0 and first level is 1
      return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
    },
    getXp(level) {
      return LEVELS[level] ?? 0
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
